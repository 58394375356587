import React, { useEffect } from "react";
import { useLocation } from '@gatsbyjs/reach-router';
import { useTranslation } from 'react-i18next';
import { ParallaxProvider } from 'react-scroll-parallax';

import PostWrapper from './PostWrapper';
import useComponentWillMount from 'src/hooks/useComponentWillMount';
import setLanguage from 'src/utils/setLanguage';
import replaceAmpersand from 'src/utils/replaceAmpersand';
import DefaultHead from 'src/components/head-defaults';
import Seo from 'src/components/seo';

import Layout from "src/components/Layout";

const Post = ( props ) => {
  const location = useLocation();
  const {i18n} = useTranslation();
  useComponentWillMount(() => {setLanguage(location.pathname, i18n)});
  useEffect(() => {
    // localStorage.setItem('articleFallbackUrl', '');
  }, []);

  console.log( props.pageContext )

  return (
    <Layout theme="light">
      <div className="post-page-wrapper">
        <ParallaxProvider>
          <PostWrapper {...props} />
        </ParallaxProvider>
      </div>
    </Layout>
  );
};

export default Post;


export const Head = ({ pageContext }) => {
  const postHeroObj = pageContext?.components?.contents.find(c => c.fieldGroupName === "post_Components_Contents_ArticleHero");
  // https://cdn.tasting-kitchen.com/wp-content/uploads/2020/07/18181242/COver_page01-scaled.jpg
  return <>
    <DefaultHead />
    <Seo
      title={replaceAmpersand(pageContext?.title) || "Tasting Kitchen (TK) - Articles"}
      articleUrl={pageContext?.url}
      articleLang={pageContext?.language?.slug}
      pageContext={pageContext}
      type="page"
    />
  </>;
}
