import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-scroll-parallax';
import useWindow from 'src/hooks/useWindow';
import { addWidthParam, makeSrcSetX } from 'utils/addWidthParam'

import '../../components/TopArticlesCategory/TopArticlesCategory.scss';
// import parseStringByLanguagues from 'src/utils/parseStringByLanguagues';
// import { getFormattedArticle } from 'src/utils/formatArticle';

const getFormattedArticle = (article) => {
  if (!article) return null;

  const imageHeroObj =
    article.components.contents &&
    article.components.contents.find(
      (content) =>
        content.fieldGroupName === 'post_Components_Contents_ArticleHero'
    );
  
  const isCircle =
    article.components.contents &&
    article.components.contents.find(
      content => content.fieldGroupName === 'post_Components_Contents_CircleThumbnail'
    );
      

  const category = article.categories.nodes.find(
    (category) => category.name.toLowerCase() !== 'featured category'
  );

  const bylineObj =
    article.components.contents &&
    article.components.contents.find((content) => content.byline);

  const imageUrl = article.featuredImage ? article.featuredImage.sourceUrl : null

  const formattedArticle = {
    dateGmt: article.dateGmt,
    imageUrl: imageUrl,
    category: category ? category.name : '',
    title: (imageHeroObj && imageHeroObj.title) || article.title,
    byline: bylineObj && bylineObj.byline,
    articleUrl: article.uri,
    isCircle: isCircle && isCircle.isFeaturedImageRounded,
    authors:
      imageHeroObj && imageHeroObj.authors ? `${imageHeroObj.authors}` : null,
    // articleCircleThumbnail: article.articleCircleThumbnail,
  };

  return formattedArticle;
};

function formatDate( str, lang ){
  const d = new Date(str+'Z'),
        year = d.getFullYear(),
        month = d.getMonth(),
        day = d.getDate();
  
  const months = {
    'en': ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec']
  }

  let dateString = '';

  switch(lang){
    case "en":
      dateString = months[lang][month] + ' ' + day;
      if( year != (new Date()).getFullYear() ) {
        dateString += ', ' + year;
      }
      break;
    default:
      let m = month + 1;
      dateString = year + '/' + (m>9?m:('0'+m)) + '/' + (day>9?day:('0'+day))
  }

  return dateString;
} // formatDate

function Article({ title, byline, category, imageUrl, articleUrl, isCircle, t, i18n, dateGmt }) {
  const _window = useWindow();  
  const [width, setWidth] = useState( 179 );

  useEffect(() => {
    let windowResizeListener = null;
    if(_window) {
      function calculateWidth(){
        const windowWidth = _window.innerWidth
        let width = 179
        if( windowWidth < 1440 ) {
          width = Math.round( ( windowWidth - 160 ) / 3 )
        }
        if ( windowWidth < 769 ) {
          width = windowWidth - 40
        }
        return width
      }

      setWidth( calculateWidth() );

      windowResizeListener = _window.addEventListener('resize', () => {
        setWidth( calculateWidth() );
      });
    }
    return () => {
      if(_window && windowResizeListener) {
        _window.removeEventListener(windowResizeListener);
      }
    }
  }, [_window]);

  return (
    <Link to={articleUrl} className="top_articles__columns__column__inner">
      {imageUrl && (
        <div className={cx('parallax-outer', 'article-img-wrapper', { 'article-circle': isCircle })}>
          <Parallax className="parallax-inner" translateY={[-5, 5]}>
            {isCircle && (
              <div className='article-circle-img-container'>
                <img src={addWidthParam(imageUrl, width)} srcSet={ makeSrcSetX( imageUrl, width ) } className="article-img" alt="Chef" />
              </div>
            )}

            {!isCircle && (
              <img src={addWidthParam(imageUrl, width)} srcSet={ makeSrcSetX( imageUrl, width ) } className="article-img" alt="Chef" />
            )}
          </Parallax>
        </div>
      )}
      {category && <div className="article__category" dangerouslySetInnerHTML={{ __html: category }} />}
      {title && <div className="article__title">{title}</div>}
      {dateGmt && <div className="article__date">{ formatDate(dateGmt,i18n.language) }</div> }
      {byline && <div className="article__description">{byline}</div>}
      <div className="more-divider" />
      {articleUrl && (
        <div className="article__more">
          <span className="article__more__link">{t('read-more')}</span>
        </div>
      )}
    </Link>
  );
}

const getArticles = (articles) => {
  return articles ? articles.map((articleObj) => {
      const { article, articleCircleThumbnail } = articleObj;
      const newArticle = getFormattedArticle(article);
      return { ...newArticle, articleCircleThumbnail };
    }) : [];
};

export default function BlogPosts(props) {
  const [t, i18n] = useTranslation('article');
  // const _window = useWindow();  

  const { pageContext } = props

  const rawPosts = pageContext.posts.map( p => ({ article: p.post }) )
  const articles = getArticles( rawPosts )

  // console.log( "in Posts", pageContext.posts );

  return (
    <div className="top-articles-container section-landing">
      <div className={`top-articles__more container`}>
          { articles.map( ( article, index ) => (
            <div className="top__articles__category__item" key={index}>
                <Article
                {...{
                  ...article,
                  key: index,
                  t,
                  i18n
                }}
              />
            </div>
          ))}
      </div>
    </div>
  )
}