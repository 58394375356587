import React from "react";
// import Cookies from 'js-cookie';
// import { useParallaxController } from 'react-scroll-parallax';
// import { isAuthenticated } from "src/utils/auth";
// import replaceAmpersand from 'src/utils/replaceAmpersand';
// import Layout from "src/components/Layout";
// import PostLayouts from "src/components/PostLayouts";
// import PageLimitModal from 'src/components/PageLimitModal/PageLimitModal';
import BlogPosts from './BlogPosts'
import Pagination from '../../components/common/Pagination/Pagination'

const PostWrapper = (props) => {
  // const parallaxController = useParallaxController();

  // const { pageContext } = props;

  return (
    <div className="section-landing">

      <h1 className="category-title">Tasting Kitchen (TK)</h1>

      <BlogPosts {...props}/>

      <Pagination {...props} />
    </div>
  );

  // return <div>{ JSON.stringify(props) }</div>;

}

export default PostWrapper;
